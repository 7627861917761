//== Layout
//## Footer

.footer {
    a {
        color: $white;
        &:hover {
            color: lighten($primary, 25%);
        }
    }
}
