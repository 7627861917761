//== Base
//## General

html {
    overflow: hidden;
    background-color: #fff;
}

body, html {
    height: 100%
}
body {
    margin: 0;
    overflow: auto;
}

.page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    #maincontent {
        flex-grow: 1;
    }
}

.product-info-table {
    font-size: 14px;
}

.pdf-view-wrapper {
    iframe, embed {
        border: 0;
    }
}

.product-list {
    & &-item {
        display: block;
        color: initial;
    }
}

.tag-list {
    a {
        white-space: nowrap;
    }
}
