//== Base
//## Forms

#search-inputautocomplete-list {
    position: absolute;
    background-color: white;
    border: 1px solid $gray-400;
    z-index: 900;
    border-radius: 0.3rem;
    width: 100%;
    margin-top: 5px;
    font-size: 1.25rem;
    overflow: hidden;
    > div {
        padding: 0.5rem 1rem;
        &:hover {
            background-color: $light;
            cursor: pointer;
        }
    }
}

.autocomplete {
    input {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
}

.ui-autocomplete {
    position: absolute;
    top: auto !important;
    width: 100% !important;
    margin: 5px 0 0 0;
    padding: 0;
    background-color: white;
    border: 1px solid $gray-400;
    z-index: 900;
    border-radius: 0.3rem;
    font-size: 1.25rem;
    overflow: hidden;
    list-style: none;
    box-shadow: $box-shadow;
    > li {
        &:nth-child(n + 11) {
            display: none;
        }
        > div {
            padding: 0.5rem 1rem;
            &:hover {
                background-color: $light;
                cursor: pointer;
            }
            &.ui-state-active {
                background-color: $primary;
                color: $white;
            }
        }
    }
}