/* Activated editbars */
.fe-admin {
  .editbar-container {
    margin-bottom:10px;
  }
  .carousel-control-next,
  .carousel-control-prev {
    height: 20px;
    top: 50%;
  }
  footer {
    display: block;
  }
  .pcms-collection {
    margin: 0;
  }
  .card {
    background-color: transparent;
    border: 0;
    .card-header {
      background-color: #f7f7f7;
      padding: .125rem 1rem 0;
      .dropdown-toggle {
        &:hover {
          text-decoration: none;
        }
      }
      margin-bottom: .5rem;
    }
  }
  > .card {
    &:first-of-type {
      margin-bottom: 0 !important;
    }
  }
}
