.product-list {
    .product-list-item {
        display: flex;
        align-items: center;
        color: $gray-900;
        &::-moz-focus-inner {
          border: 0;
        }
        &:focus {
            outline:none;
        }
        .product-info {
            flex-grow: 1;
            p, span {
                font-size: 0.8125rem;
            }
            span {
                color: $gray-500;
            }
        }
        .product-media {
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(80px + 2 * #{$spacing-sm});
            height: calc(80px + 2 * #{$spacing-sm});
            padding: $spacing-sm;
            margin-left: $spacing-md;
            flex-shrink: 0;
            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 60px;
                /* IE ONLY */
                @media all and (-ms-high-contrast:none) {
                    min-width: 1px;
                    min-height: 1px;
                }
            }
        }
    }
}
