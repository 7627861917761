//== Base
//## Type/Typography

a:not(.btn) {
    transition: color .15s ease-in-out;
    &:hover {
        text-decoration: none;
    }
}

a.bg-light {
    transition: color .15s ease-in-out, background-color .15s ease-in-out;
}
