.product-info {
    .product-info-table {
        .product-media {
            a {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                height: 80px;
                width: 70%;
                img {
                    height: auto;
                    width: auto;
                    max-width: 100%;
                    max-height: 100%;
                    /* IE ONLY */
                    @media all and (-ms-high-contrast:none) {
                        min-width: 1px;
                        min-height: 1px;
                    }
                }
            }
        }
    }
    #show-more-btn {
        .less {
            display: none;
        }
        .more {
            display: inline;
        }
        &.open {
            .less {
                display: inline;
            }
            .more {
                display: none;
            }
        }
    }
    #long-desc-container {
        flex-grow: 1;
        position: relative;
        overflow: hidden;
        &:after {
            content: " ";
            display: block;
            height: 50px;
            position: absolute;
            bottom: 0;
            width: 100%;
            background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1));
        }
        .long-desc-content-wrapper {
            position: absolute;
        }
        &.open {
            &:after {
                display: none;
            }
            .long-desc-content-wrapper {
                position: static;
            }
        }
        @include respond-below("lg") {
            &:not(.open) {
                height: 200px;
            }
        }
    }
}
