//== Module
//## Search

.search-form.result {
    .typeahead__result {
        display: block;
    }
}
.typeahead__result {
    display: none;
}
