// Link more SASS files like this
//@import "app/assets/stylesheets/XYZ";

@import "/lib/jquery-typeahead/dist/jquery.typeahead.min.css";
@import "fontawesome/fontawesome";
@import "fontawesome/brands";
@import "fontawesome/regular";
@import "fontawesome/solid";


// bootstrap variable overwrites have to be importet before bootstrap
@import "base/variables";
@import "bootstrap/scss/bootstrap";


//== Utility Classes
//## Helper Classes
@import "abstracts/animations";
@import "abstracts/mixins";

//== Core CSS
//## Global Elements/Typography/Forms etc.
@import "base/general";
@import "base/type";
@import "base/forms";
@import "base/fonts";


//== Components
//## Reusable Components and Elements
@import "components/buttons";
@import "components/product-teaser";


//== Modules
@import "modules/search";
@import "modules/editbar";


//== Layout
//## Section-Specific Components and Elements
@import "layout/footer";
@import "layout/header";
@import "modules/detail-page";


//== Shame
//## http://csswizardry.com/2013/04/shame-css/
@import "base/shame";

// Your SASS code goes here

