//== Layout
//## Header

.navbar {
    .navbar-brand {
        margin: 0;
        height: 70px;
        @include respond-below("xl") {
            height: 55px;
        }
        @include respond-below("lg") {
            height: 45px;
        }
        @include respond-below("md") {
            height: 40px;
        }
        img {
            display: block;
            height: 100%;
        }
    }
    .header-links {
        @include respond-above("md") {
            position: absolute;
            right: 0;
            top: 10px;
        }
        a {
            font-size: 1.5rem;
            color: $gray-400;
            margin: 0 $spacing-lg;
            @include respond-below("md") {
                font-size: 1.2rem;
            }
            @include respond-below("lg") {
                margin: 0 $spacing-md;
            }
            &:hover {
                color: $primary;
            }
        }
    }
}

.page-title {
    word-break: break-word;
    hyphens: manual;
}

.navbar {
    > .container {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}
